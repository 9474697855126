import { abstractField } from 'vue-form-generator'
import Multiselect from 'vue-multiselect'
import ErrorNotify from '../../../../share/mixins/ErrorNotify'
import _ from 'lodash'
import api from '../../../../../api'

export default {
  name: 'vhs-multiselect',
  template: `
    <div :id="'task-' + schema.model" style="float: left; width: 100%">
      <multiselect
        :id="'input-' + schema.model"
        v-model="multiselectValue"
        :label="viewOption"
        :options="optionsList"
        :track-by="trackBy"
        :multiple="multiple"
        :disabled="disabled"
        :selectLabel="selectLabel"
        :selectedLabel="selectedLabel"
        :placeholder="placeholder"
        :deselectLabel="deselectLabel"
        :selectGroupLabel="selectGroupLabel"
        :deselectGroupLabel="deselectGroupLabel"
        :loading="asyncLoading"
        @input="updateValue"
        @search-change="asyncFind"
        @close="onBlur(onBlurEvent)"
        >
        <template slot="noResult">
          <p>{{$t('pages.taskDetails.form.multiselect.fieldMultiselect.noData')}}</p>
        </template>
        <template slot="noOptions">
          <p>{{$t('pages.taskDetails.form.multiselect.fieldMultiselect.noData')}}</p>
        </template>
      </multiselect>
    </div>
  `,
  components: {
    Multiselect
  },
  mixins: [
    abstractField,
    ErrorNotify
  ],
  data () {
    return {
      debounceTimer: null,
      optionsList: [],
      asyncLoading: false
    }
  },
  watch: {
    'model.make' () {
      if (this.schema.model === 'model') {
        this.optionsList = []
      }
    },
    accidentParticipantsMakeToWatch (newVal, oldVal) {
      if (newVal !== undefined && newVal !== oldVal && this.schema.model === `accidentParticipants[${this.schema.collectionIndex}].model`) {
        this.optionsList = []
        this.$events.$emit('schema:accidentParticipantsMakeChanged', this.schema.model, this.schema.collectionIndex)
      }
    }
  },
  computed: {
    source () { return this.schema.source !== undefined ? this.schema.source : {} },
    mapOptions () { return this.schema.params.mapOptions !== undefined ? this.schema.params.mapOptions : 'label' },
    viewOption () { return this.schema.params.viewOption !== undefined ? this.schema.params.viewOption : '' },
    trackBy () { return this.schema.params.trackBy !== undefined ? this.schema.params.trackBy : 'id' },
    multiple () { return this.schema.params.multiple !== undefined ? this.schema.params.multiple : false },
    selectLabel () { return this.schema.params.selectLabel !== undefined ? this.schema.params.selectLabel : this.$t('pages.taskDetails.form.multiselect.fieldMultiselect.selectLabel') },
    selectedLabel () { return this.schema.params.selectedLabel !== undefined ? this.schema.params.selectedLabel : this.$t('pages.taskDetails.form.multiselect.fieldMultiselect.selectedLabel') },
    placeholder () { return this.schema.params.placeholder !== undefined ? this.schema.params.placeholder : this.$t('pages.taskDetails.form.multiselect.fieldMultiselect.placeholder') },
    deselectLabel () { return this.schema.params.deselectLabel !== undefined ? this.schema.params.deselectLabel : this.$t('pages.taskDetails.form.multiselect.fieldMultiselect.deselect') },
    selectGroupLabel () { return this.schema.params.selectGroupLabel !== undefined ? this.schema.params.selectGroupLabel : 'zaznacz grupę' },
    deselectGroupLabel () { return this.schema.params.deselectGroupLabel !== undefined ? this.schema.params.deselectGroupLabel : 'odznacz grupę' },
    multiselectValue: {
      get () {
        let val = null
        let modelValue = this.getModelValue(this.schema.model)

        if (typeof modelValue === 'string') {
          const entityObj = {}
          entityObj[this.mapOptions.optionId] = null
          entityObj[this.mapOptions.optionLabel] = modelValue

          val = Object.assign({}, entityObj, { entity: entityObj })
        } else if (modelValue !== undefined && modelValue !== null) {
          let options = this.getMappedOptions([modelValue])
          val = options[0]
        }

        return val
      },
      set (newVal) {
        return newVal
      }
    },
    onBlurEvent () {
      return { target: this.$el }
    },

    // collection field parts
    accidentParticipantsMakeToWatch () {
      if (this.model.accidentParticipants === undefined || this.model.accidentParticipants === null) {
        return null
      }
      return this.model.accidentParticipants[this.schema.collectionIndex] ? this.model.accidentParticipants[this.schema.collectionIndex].make : null
    }
  },
  methods: {
    getModelValue (objectPath) {
      return _.get(this.model, objectPath, null)
    },
    updateValue (value) {
      if (this.schema.multiple) {
        let selected = []

        for (let c = 0; c < value.length; ++c) {
          let tmpObj = {}
          tmpObj[this.schema.trackBy] = value[c][this.schema.trackBy]
          selected.push(tmpObj)
        }

        this.value = (selected.length > 0) ? selected : null
      } else {
        this.value = (value !== undefined && value !== null) ? value.entity : null
      }
    },
    asyncFind (query) {
      if (!_.isEmpty(this.source)) {
        if (query.match(/^\s*$/)) {
          return
        }

        this.search(query)
      }
    },
    search (query) {
      this.asyncLoading = true
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        let params = []
        params.push('terms=' + encodeURIComponent(query))

        if (_.has(this.source, 'additionalParams')) {
          for (let i = 0; i < this.source.additionalParams.length; ++i) {
            let param = this.source.additionalParams[i].model
            param = param.replace(/\[i\]/g, '[' + this.schema.collectionIndex + ']')
            let modelValue = this.getModelValue(param)
            if (modelValue !== undefined && modelValue !== null) {
              if (typeof modelValue === 'object') {
                params.push(`${this.source.additionalParams[i].paramName}=${modelValue[this.source.additionalParams[i].modelPath]}`)
              } else {
                params.push(`${this.source.additionalParams[i].paramName}=${modelValue}`)
              }
            }
          }
        }
        if (this.$isWithClients(this.$route.meta.acl.service)) {
          const move = (clientId) => params.push(`clientId=${this.$store.state.base.user.serviceClients[this.$route.meta.acl.service].find(client => parseInt(client.id) === parseInt(clientId)).uuid}`)

          if (this.$route.meta.client) {
            move(this.$route.meta.client)
          }
          if (this.$route.params.clientSymbol) {
            move(this.$route.params.clientSymbol)

          }
        }

        let paramsUri = _.join(params, '&')

        api.request(this.source.service, this.source.method, `${this.source.url}&${paramsUri}`)
          .then((response) => {
            this.optionsList = this.getMappedOptions(response.data)
          this.asyncLoading = false

        })
          .catch((error) => {
          this.asyncLoading = false
          this.errorNotify(error)
          })
      }, 500)
    },
    getMappedOptions (data) {
      let options = []

      for (let c = 0; c < data.length; ++c) {
        let optionLabel
        let optionId = data[c][this.mapOptions.optionId]

        if (Array.isArray(this.mapOptions.optionLabel)) {
          let labelPart = []

          for (let i in this.mapOptions.optionLabel) {
            labelPart.push(data[c][this.mapOptions.optionLabel[i]])
          }

          optionLabel = _.join(labelPart, ' ')
        } else {
          optionLabel = data[c][this.mapOptions.optionLabel]
        }

        options.push({ label: optionLabel, id: optionId, entity: data[c] })
      }

      return options
    }
  }
}
