import _ from 'lodash'
import api from '../../../../api'
import { Engine } from 'json-rules-engine'
import ErrorNotify from '../../../share/mixins/ErrorNotify'
import fieldAutocomplete from '../../share/form/type/fieldAutocomplete'
import fieldMultiselect from '../../share/form/type/fieldMultiselect.js'
import fieldPersonMultiselect from '../../share/form/type/fieldPersonMultiselect.js'
import fieldCollection from '../../share/form/type/fieldCollection'
import fieldMoney from '../../share/form/type/fieldMoney'
import FieldRequirementValidator from './FieldRequirementValidator'
import Loader from '../../../share/Loader'
import Vue from 'vue'
import VueFormGenerator from 'vue-form-generator'
import postCodeMultiselect from '../../share/form/type/postCodeMultiselect'
import vhsMultiselect from '../../share/form/type/vhsMultiselect'

Vue.component('field-postCodeMultiselect', postCodeMultiselect)
Vue.component('field-vhsMultiselect', vhsMultiselect)
Vue.component('fieldAutocomplete', fieldAutocomplete)
Vue.component('fieldMultiselect', fieldMultiselect)
Vue.component('vhsMultiselect', vhsMultiselect)
Vue.component('fieldPersonMultiselect', fieldPersonMultiselect)
Vue.component('fieldCollection', fieldCollection)
Vue.component('fieldMoney', fieldMoney)

export default {
  template: `
    <form v-if="isVisible">
      <vue-form-generator
        :schema="schema"
        :model="model"
        :options="formOptions"
        @model-updated="modelUpdated"
        @field-updated="submitField"
        @collection-updated="submitCollection"
      ></vue-form-generator>
    </form>
  `,
  components: {
    VueFormGenerator: VueFormGenerator.component
  },
  mixins: [
    Loader,
    ErrorNotify,
    FieldRequirementValidator
  ],
  props: {
    task: { type: Object, required: true, default () { return {} } },
    taskData: { type: Object, required: true, default () { return {} } },
    stateId: { type: Number },
    schemaName: { type: String, required: true },
    client: {type: String, required: true}
  },
  data () {
    return {
      events: {
        accidentParticipantsMakeChanged: 'schema:accidentParticipantsMakeChanged',
        collapseGroup: 'schema:collapse-group'
      },
      fieldSendDataMap: {
        voivodeship: {
          method: this.getModelDataByPath,
          args: ['label']
        },
        county: {
          method: this.getModelDataByPath,
          args: ['label']
        },
        borough: {
          method: this.getModelDataByPath,
          args: ['label']
        },
        city: {
          method: this.getModelDataByPath,
          args: ['label']
        },
        postCode: {
          method: this.getModelDataByPath,
          args: ['postcode']
        },
        // collection fields
        accidentParticipants: {
          method: this.prepareAccidentParticipantsData
        },
        subjectCoOwners: {
          method: this.prepareSubjectCoOwnersData
        },
        vehicleDrivers: {
          method: this.prepareVehicleDriversData
        },
        subjectUsers: {
          method: this.prepareSubjectUsersData
        },
        victims: {
          method: this.prepareVictimsData
        },

        // vehicle fields
        make: {
          method: this.getModelDataByPath,
          args: ['label']
        },
        model: {
          method: this.getModelDataByPath,
          args: ['label']
        },
        policeDepartment: {
          method: this.getModelDataByPath,
          args: ['label']
        },
        bankAssignment: {
          method: this.getModelDataByPath,
          args: ['label']
        }
      },
      rulesEngine: this.createRulesEngine(),
      isVisible: false,
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: false,
        fieldIdPrefix: 'task-'
      },
      model: {},
      modelChanged: {},
      isFirstState: true,
      schema: {
        groups: []
      },
      schemaCollection: {}
    }
  },
  watch: {
    'model.make': function (newVal, oldVal) {
      if (oldVal !== undefined) {
        this.model.model = null
      }
    }
  },
  mounted () {
    this.loadSchema()
    this.initFieldsListeners()
    this.$store.commit('SET_CURRENT_TASK_MODEL', this.model)
  },
  updated () {
    this.validateRequirement()
  },
  methods: {
    initFieldsListeners () {
      this.$events.on(this.events.accidentParticipantsMakeChanged, this.resetCollectionField)
      this.$events.on(this.events.collapseGroup, this.collapseGroup)
    },
    createRulesEngine () { return new Engine() },
    loadData () {
      this.model = Object.assign({}, this.model, this.taskData)
      this.$store.commit('SET_CURRENT_TASK_MODEL', this.model)
      if (this.task.state) {
        this.$events.$emit('dashboard:submenu:mainTaskInInitialState', this.task.state.initial)
        if (!this.task.state.initial && !this.task.state.final) {
          this.$events.$emit('dashboard:submenu:showAll')
        }
      }
    },
    getLoadSchemaMethod () {
      return this.$isWithClients(this.service)
        ? api.request(this.service, 'get', `/schemas/${this.schemaName}`, {
          state: this.stateId,
          client: this.client
        })
        : api.request(this.service, 'get', `/schemas/${this.schemaName}`, { state: this.stateId })
    },
    loadSchema () {
      this.getLoadSchemaMethod()
        .then((result) => {
          this.loadData()
          this.buildSchema(result.data)
          this.$store.commit('SET_CURRENT_TASK_SCHEMA', result.data)

          this.isVisible = true
          this.$notify({
            type: 'success',
            text: this.$t('pages.taskDetails.form.successSchemaLoadNotification')
          })
        }).then(() => {
          this.$events.$emit('dashboard:submenu:taskLoaded')
        })
        .catch(() => {
          this.toggleLoading()
          this.$notify({
            type: 'error',
            text: this.$t('pages.taskDetails.form.errorSchemaLoadNotification')
          })
        })
    },
    buildSchema (schema) {
      for (let g = 0; g < schema.groups.length; g++) {
        const group = schema.groups[g]
        let fields = []
        let groupFields = Object.values(group.fields)
        for (let f = 0; f < groupFields.length; f++) {
          let baseField = groupFields[f]

          if (baseField.field.settings && baseField.field.settings.collection) {
            let collectionName = baseField.field.settings.collection

            if (this.schemaCollection[collectionName] === undefined) {
              this.schemaCollection[collectionName] = []
            }

            this.schemaCollection[collectionName].push(baseField)

            let collection = { 'collection': collectionName }
            if (_.find(fields, collection) === undefined) {
              fields.push(collection)
            }
          } else {
            fields.push(this.createField(baseField))
          }
        }
        if (group.fields.length > 0) {
          this.schema.groups.push(this.createGroup(group, fields))
        }
      }

      this.addFieldCollectionToSchema()
    },
    addFieldCollectionToSchema () {
      for (let g = 0; g < this.schema.groups.length; g++) {
        let group = this.schema.groups[g]
        let groupFields = Object.values(group.fields)
        for (let f = 0; f < groupFields.length; f++) {
          let baseField = groupFields[f]

          if (baseField.collection !== undefined) {
            let baseCollectionFields = []
            let collectionFields = this.schemaCollection[baseField.collection]
            let fieldModel = this.model[baseField.collection]
            let collectionLength = this.getCollectionLength(fieldModel)

            this.$set(this.schema.groups[g].fields[f], 'type', 'collection')
            this.$set(this.schema.groups[g].fields[f], 'model', 'model')
            this.$set(this.schema.groups[g].fields[f], 'schema', { groups: [] })

            for (let c = 0; c < collectionLength; c++) {
              let fields = []

              for (let f = 0; f < collectionFields.length; f++) {
                let createdField = this.createField(collectionFields[f], c)
                createdField.collectionName = baseField.collection
                fields.push(createdField)

                if (c === 0) {
                  let collectionField = _.cloneDeep(createdField)
                  collectionField.model = collectionFields[f].field.model
                  baseCollectionFields.push(collectionField)
                }
              }

              this.schema.groups[g].fields[f]['schema']['groups'].push({ fields: fields })
            }

            this.$set(this.schema.groups[g].fields[f], 'baseCollectionFields', baseCollectionFields)
            this.$set(this.schema.groups[g], 'fieldCollection', true)
          }
        }
      }
    },
    createGroup (group, fields) {
      let result = {
        fields
      }
      if (group.label) {
        result.legend = group.label
      }
      return result
    },
    createField (fieldItem, collectionIndex = null) {
      let field = fieldItem.field

      let fieldParams = {
        type: field.type,
        label: field.label,
        inputName: field.model,
        disabled: this.isFieldDisabled(fieldItem),
        required: fieldItem.required ? fieldItem.required : false,
        relatedFields: []
      }

      let fieldModel = this.getPrepareFieldModel(field, collectionIndex)
      Object.assign(fieldParams, fieldModel)

      if (field.settings !== null) {
        Object.assign(fieldParams, this.setSettings(field))
      }

      return fieldParams
    },
    setSettings (field) {
      let settings = field.settings

      if (settings.value) {
        this.model[field.model] = settings.value
      }

      let fieldParams = {}
      fieldParams.readonly = settings.readonly ? settings.readonly : false
      fieldParams.relatedFields = settings.relatedFields ? settings.relatedFields : []
      Object.assign(fieldParams, this.setSpecificSettings(field))

      return fieldParams
    },
    setSpecificSettings (field) {
      let fieldParams = {}

      switch (field.type) {
        case 'input':
          fieldParams.inputType = field.inputType
          fieldParams.maxlength = field.settings.maxlength
          break

        case 'select':
          fieldParams.values = field.settings.values
          fieldParams.selectOptions = { hideNoneSelectedText: true }
          break

        case 'radios':
          fieldParams.values = field.settings.values
          break

        case 'multiselect':
        case 'vhsMultiselect':
        case 'postCodeMultiselect':
        case 'personMultiselect':
          fieldParams = this.prepareMultiselectParams(field)
          break

        case 'textArea':
          fieldParams.max = field.settings.maxlength
          break
        case 'dateTimePicker':
          fieldParams.dateTimePickerOptions = field.settings.dateTimePickerOptions
      }

      return fieldParams
    },
    isFieldDisabled (fieldItem) {
      if (_.has(fieldItem, 'field.settings.readonly')) {
        return fieldItem.field.settings.readonly
      }
      return fieldItem.readonly ? fieldItem.readonly : false
    },
    getCollectionLength (fieldModel) {
      let collectionLength = 1

      if (_.isArray(fieldModel) && fieldModel.length > 1) {
        collectionLength = fieldModel.length
      }

      return collectionLength
    },
    resetCollectionField (collectionPath) {
      if (_.has(this.model, collectionPath)) {
        _.set(this.model, collectionPath, null)
      }
    },
    prepareMultiselectParams (field) {
      let fieldParams = {}
      let fieldModel = this.model[field.model]

      if (field.settings.params) {
        fieldParams.params = field.settings.params
      }

      if (field.settings.source) {
        fieldParams.source = field.settings.source
      }

      if (field.settings.options) {
        fieldParams.options = field.settings.options
      }

      if (_.isArray(fieldModel) && !_.isEmpty(fieldModel)) {
        let collectionField = fieldModel[field.collectionIndex]

        let options = {
          id: collectionField.id,
          label: collectionField.forename + ' ' + collectionField.surname,
          entity: collectionField
        }

        fieldParams.options = [options]
        fieldParams.value = options
      }

      return fieldParams
    },
    getPrepareFieldModel (field, collectionIndex = null) {
      let fieldParams = {}
      let fieldModel = field.model

      if (collectionIndex !== null) {
        fieldParams.collectionIndex = collectionIndex
        fieldParams.model = fieldModel.replace(/\[i\]/g, '[' + collectionIndex + ']')
        fieldParams.modelPlaceholder = field.model
      } else {
        fieldParams.model = field.model
      }

      return fieldParams
    },
    formatSourceModelName (source) {
      return source.split('').filter(char => char !== '[' && char !== ']' && char !== '.').join('')
    },
    modelUpdated (e, source) {
      let model = this.modelChanged
      if (typeof source === 'object') {
        model[this.formatSourceModelName(source.collectionName + 'Collection')] = true
      } else {
        model[this.formatSourceModelName(source)] = true
      }
      this.modelChanged = model
      this.validateRequirement()
    },
    submitField (newVal, schema, target) {
      if (!this.modelChanged[this.formatSourceModelName(schema.model)]) {
        return
      }
      if(schema.type === 'postCodeMultiselect' && newVal !== null && newVal.hasOwnProperty('city')) {
        newVal.borough = newVal.municipality
        Object.entries(newVal).filter(([key,value], index) => schema.relatedFields.includes(key)).forEach(([key,value], index) => {
          this.model[key] = value
        })
      }

      this.$store.commit('SET_CURRENT_TASK_MODEL', this.model)
      target.classList.add('saving-field')

      // wait for all watchers to finish
      // schemaName should be workflowName
      this.$nextTick(() => {
        api.request(this.service, 'put', !this.$isWithClients(this.service) ? `/tasks/${this.schemaName}/${this.$route.params.id}` : `/tasks/${this.$route.params.id}`, this.createFieldDataToSend(newVal, schema))
          .then(() => {
            this.modelChanged[this.formatSourceModelName(schema.model)] = false
            target.classList.remove('saving-field')
            target.classList.add('field-saved')
            setTimeout(() => target.classList.remove('field-saved'), 1500)
          })
          .catch(() => {
            this.modelChanged[this.formatSourceModelName(schema.model)] = false
            target.classList.remove('saving-field')
            target.classList.add('field-error')
            setTimeout(() => target.classList.remove('field-error'), 3600)
            this.$notify({
              type: 'error',
              text: this.$t('pages.taskDetails.form.errorSaveData')
            })
          })
      })
    },
    createFieldDataToSend (newVal, schema) {
      let dataToSend = {}

      if (this.fieldSendDataMap[schema.model] !== undefined) {
        newVal = this.fieldSendDataMap[schema.model].method(newVal, ...this.fieldSendDataMap[schema.model].args)
      }
      dataToSend[schema.model] = newVal

      schema.relatedFields.forEach((relatedField) => {
        if (this.fieldSendDataMap[relatedField] !== undefined) {
          dataToSend[relatedField] = this.fieldSendDataMap[relatedField].method(this.model[relatedField], ...this.fieldSendDataMap[relatedField].args)
        }
      })
      if (this.$isWithClients(this.service)) {
        dataToSend['main'] = true
        dataToSend['client'] = this.client
      }

      return dataToSend
    },
    submitCollection (collectionNewVal, schema, target, shouldRemove) {
      if (!this.modelChanged[this.formatSourceModelName(schema.hasOwnProperty('model') ? schema.model : schema.collectionName + 'Collection')]) {
        return
      }

      if (!shouldRemove) {
        target.classList.add('saving-field')
      }

      // wait for all watchers to finish
      this.$nextTick(() => {
        api.request(this.service, 'put', !this.$isWithClients(this.service) ? `/tasks/${this.schemaName}/${this.$route.params.id}?type=collection` : `/tasks/${this.$route.params.id}`, this.createCollectionDataToSend(collectionNewVal, schema, shouldRemove))
          .then((response) => {
            this.modelChanged[this.formatSourceModelName(schema.hasOwnProperty('model') ? schema.model : schema.collectionName + 'Collection')] = false

            // update given collection item id
            if (response.data.processedEntityId) {
              this.model[schema.collectionName][schema.collectionIndex].id = response.data.processedEntityId
            }

            if (shouldRemove) {
              this.$notify({
                type: 'success',
                text: 'Usunięto wpis'
              })
            } else {
              target.classList.remove('saving-field')
              target.classList.add('field-saved')
              setTimeout(() => target.classList.remove('field-saved'), 1500)
            }
          })
          .catch(() => {
            this.modelChanged[this.formatSourceModelName(schema.hasOwnProperty('model') ? schema.model : schema.collectionName + 'Collection')] = false
            if (!shouldRemove) {
              target.classList.remove('saving-field')
              target.classList.add('field-error')
              setTimeout(() => target.classList.remove('field-error'), 3600)
            }

            this.$notify({
              type: 'error',
              text: this.$t('pages.taskDetails.form.errorSaveData')
            })
          })
      })
    },
    createCollectionDataToSend (collectionNewVal, schema, shouldRemove) {
      let dataToSend = {}
      dataToSend.collectionName = schema.collectionName

      if (this.$isWithClients(this.service)) {
        dataToSend.type = 'collection'
      }

      if (this.fieldSendDataMap[schema.collectionName] !== undefined) {
        collectionNewVal = this.fieldSendDataMap[schema.collectionName].method(collectionNewVal)
      }
      dataToSend[schema.collectionName] = collectionNewVal

      if (shouldRemove) {
        dataToSend.propertiesChanged = Object.keys(collectionNewVal).filter(prop => {
          const propsToBeOmitted = [
            'id',
            'mainTask',
            'personFullname'
          ]
          return !propsToBeOmitted.includes(prop)
        }).map(prop => schema.collectionName + '[i].' + prop)

        dataToSend.action = 'delete'
      } else {
        // convert to default collection property name to match form field model name
        dataToSend.propertiesChanged = [schema.model.replace(/\[\d+\]/g, '[i]')]
      }
      if (this.$isWithClients(this.service)) {
        dataToSend['main'] = true
        dataToSend['client'] = this.client
      }

      return dataToSend
    },
    prepareAccidentParticipantsData (collectionNewVal) {
      const make = this.getModelDataByPath(collectionNewVal, 'make.label')
      return {
        id: this.getModelDataByPath(collectionNewVal, 'id'),
        mainTask: this.$route.params.id,
        person: this.getModelDataByPath(collectionNewVal, 'person.id'),
        personFullname: this.concatPersonDataForHistory(collectionNewVal),
        participantRole: this.getModelDataByPath(collectionNewVal, 'participantRole'),
        make: make,
        model: make ? this.getModelDataByPath(collectionNewVal, 'model.label') : null,
        registrationNumber: this.getModelDataByPath(collectionNewVal, 'registrationNumber')
      }
    },
    prepareSubjectCoOwnersData (collectionNewVal) {
      return {
        id: this.getModelDataByPath(collectionNewVal, 'id'),
        mainTask: this.$route.params.id,
        person: this.getModelDataByPath(collectionNewVal, 'person.id'),
        personFullname: this.concatPersonDataForHistory(collectionNewVal),
        subjectCoOwnerType: this.getModelDataByPath(collectionNewVal, 'subjectCoOwnerType')
      }
    },
    prepareVehicleDriversData (collectionNewVal) {
      return {
        id: this.getModelDataByPath(collectionNewVal, 'id'),
        mainTask: this.$route.params.id,
        person: this.getModelDataByPath(collectionNewVal, 'person.id'),
        personFullname: this.concatPersonDataForHistory(collectionNewVal),
        contactPhone: this.getModelDataByPath(collectionNewVal, 'contactPhone'),
        licenceNumber: this.getModelDataByPath(collectionNewVal, 'licenceNumber'),
        licenceCategory: this.getModelDataByPath(collectionNewVal, 'licenceCategory'),
        licenceExpiresAt: this.getModelDataByPath(collectionNewVal, 'licenceExpiresAt')
      }
    },
    prepareSubjectUsersData (collectionNewVal) {
      return {
        id: this.getModelDataByPath(collectionNewVal, 'id'),
        mainTask: this.$route.params.id,
        person: this.getModelDataByPath(collectionNewVal, 'person.id'),
        personFullname: this.concatPersonDataForHistory(collectionNewVal),
        subjectUserType: this.getModelDataByPath(collectionNewVal, 'subjectUserType')
      }
    },
    prepareVictimsData (collectionNewVal) {
      return {
        id: this.getModelDataByPath(collectionNewVal, 'id'),
        mainTask: this.$route.params.id,
        person: this.getModelDataByPath(collectionNewVal, 'person.id'),
        personFullname: this.concatPersonDataForHistory(collectionNewVal),
        victimType: this.getModelDataByPath(collectionNewVal, 'victimType'),
        contactPhone: this.getModelDataByPath(collectionNewVal, 'contactPhone')
      }
    },
    getModelDataByPath (model, prop) {
      if (typeof model === 'string') {
        return model
      }

      return _.has(model, prop) ? _.get(model, prop) : null
    },
    concatPersonDataForHistory (collectionData) {
      const personId = this.getModelDataByPath(collectionData, 'person.id')
      if (personId === null) {
        return null
      }
      const isCompany = this.getModelDataByPath(collectionData, 'person.company')
      return (isCompany ? this.getModelDataByPath(collectionData, 'person.name') : this.getModelDataByPath(collectionData, 'person.forename') + ' ' + this.getModelDataByPath(collectionData, 'person.surname')) + ' [' + personId + ']'
    },
    collapseGroup (event) {
      // TODO for future collapsing groups
      // let groupItems = document.querySelectorAll(`#${event.currentTarget.id} ~ div`)
      // groupItems.forEach((el, index) => {
      //   if (!el.classList.contains('collapse')) {
      //     // el.classList.add('collapse')
      //   } else {
      //     // el.classList.remove('collapse')
      //   }
      // })
    }
  }
}
